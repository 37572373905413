namespace eh {
    
    export class PreviewHelper {

        static readonly logger = log.getLogger('eh.PreviewHelper');
        private static _instance: PreviewHelper;
        private editorPort: MessagePort | undefined;

        static init($base: JQuery<HTMLElement>, isSnippetRequest = false): void {
            if (isSnippetRequest) {
                return;
            }
            PreviewHelper.getInstance();
        }
        
        public static getInstance(): PreviewHelper {
            if (!PreviewHelper._instance) {
                PreviewHelper._instance = new PreviewHelper();
            }
            return PreviewHelper._instance;
        }
        
        private constructor() {
            window.addEventListener('message', (event: MessageEvent) => {
                PreviewHelper.logger.debug('encountered window message', event);
                if (!this.isAllowedOrigin(event.origin)) {
                    // PreviewHelper.logger.warn('discarding event from unexpected origin', event.origin);
                    return;
                }
                if (event.data === 'ehPreviewHelperInit') {
                    if (this.editorPort) {
                        PreviewHelper.logger.warn('channel already initialized', event.type);
                    }
                    this.editorPort = event.ports[0];
                    if (!this.editorPort) {
                        PreviewHelper.logger.warn('message contains no port', event.type);
                        return;
                    }
                    this.editorPort.onmessage = (previewEvent: MessageEvent) => {
                        PreviewHelper.logger.debug('encountered channel message', previewEvent);
                        const message = previewEvent.data;
                        if (PreviewHelper.isMessageScrollToElement(message)) {
                            const elementFound = this.scrollToElement(message.uniqueElementId);
                            const m: PreviewMessage_ScrollToElementResult = {
                                type: 'scrollToElementResult',
                                uniqueElementId: message.uniqueElementId,
                                success: elementFound
                            };
                            this.editorPort?.postMessage(m);
                        }
                    };
                    const m: PreviewMessage_InitResult = {
                        type: 'initACK'
                    };
                    this.editorPort?.postMessage(m);
                }
            });
            
            const elStyle = document.createElement('style');
            elStyle.textContent = '[data-cs-highlight-preview="true"] {\n' +
                '  border-bottom: 2px solid #00c0d9;\n' +
                '  border-top: 2px solid #00c0d9;\n' +
                '}'
            ;
            document.getElementsByTagName('head')[0].appendChild(elStyle);
        }

        private isAllowedOrigin(origin: string) {
            try {
                const u = new URL(origin);
                if (/^(d|q|p)-censhare(-web-client)?\.endress\.com$/.test(u.hostname) 
                    && u.protocol === 'https:' && u.port === '') {
                    return true;
                }
                if (u.origin === 'https://localhost:9443') {
                    return true;
                }
            }
            catch (e: unknown) {
            }
            return false;
        }
        
        private scrollToElement(uniqueElementId: string): boolean {
            const currentHighlightMarker = document.querySelector('[data-cs-highlight-preview=true]');
            if (currentHighlightMarker instanceof HTMLElement) {
                delete currentHighlightMarker.dataset.csHighlightPreview;
            }
            const targetElement = document.querySelector(`[data-cs-pcueid='${uniqueElementId}']`);
            if (targetElement) {
                if (targetElement instanceof HTMLElement) {
                    targetElement.dataset.csHighlightPreview = 'true';
                }
                targetElement.scrollIntoView({block: 'center'});
                return true;
            }
            return false;
        }
        
        public static isMessageScrollToElement(data: any): data is PreviewMessage_ScrollToElement {
            return data 
                && (data as PreviewMessage_ScrollToElement).type === 'scrollToElement' 
                && typeof (data as PreviewMessage_ScrollToElement).uniqueElementId === 'string';
        }
        
    }

    type WindowMessage_PreviewHelperInit = 'ehPreviewHelperInit';
    
    type PreviewMessage = {
        type: string;
    }

    type PreviewMessage_InitResult = PreviewMessage & {
        type: 'initACK';
    }

    type PreviewMessage_ScrollToElement = PreviewMessage & {
        type: 'scrollToElement';
        uniqueElementId: string;
    }

    type PreviewMessage_ScrollToElementResult = PreviewMessage & {
        type: 'scrollToElementResult';
        uniqueElementId: string;
        success: boolean;
    }
    
}