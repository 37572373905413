$(() => {
  
  const $root = $(':root');
  $root.on(cs.Snippet.EventIdPostReplace, ($event: cs.SnippetEventPostReplace) => {
    let target = $event.replacedTarget
    if (!target && $event.originalEvent) {
      const oEvent = ($event.originalEvent as any as cs.SnippetEventPostReplace | null)
      if (oEvent?.replacedTarget) {
        target = oEvent.replacedTarget;
      }
    }
    if (target) {
      eh.Preview.init($(target), true);
    }
  });
  eh.Preview.init($root);
  
});

namespace eh {
  
  export class Preview {
    
    static init($base: JQuery<HTMLElement>, isSnippetRequest = false): void {

      eh.PreviewHelper.init($base, isSnippetRequest);

    }
  }

}
